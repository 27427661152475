@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply border shadow-sm focus:ring-blue-800 focus:border-blue-800 block w-full sm:text-sm md:text-lg border-gray-300 rounded-md;
  }

  .select {
    @apply w-full  sm:text-sm md:text-lg border-gray-300 focus:outline-none focus:ring-blue-800 focus:border-blue-800 rounded-md;
  }

  .form-title {
    @apply text-xl  leading-10 font-medium text-black;
  }
  .form-label{
    @apply text-sm  leading-10 font-medium text-black;
  }

  .button-blue {
    @apply bg-blue-cyt hover:bg-blue-900 text-white w-full py-2 px-4 rounded;
  }
  .button-blue-xl {
    @apply bg-blue-800 text-white w-full p-3 rounded hover:bg-blue-700 text-base font-semibold;
  }
  .input {
    @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-cyt sm:text-sm;
  }
  .input-error {
    @apply mt-2 text-sm text-red-600;
  }

  .container {
    @apply max-w-screen-lg;
  }

  .button-primary-mid {
    @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-cyt hover:bg-blue-cyt-alt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-cyt-alt;
  }
  .button-error-mid {
    @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600;
  }

}
