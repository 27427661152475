.img-fluid {
  max-width: 100%;
  height: auto;
}

.SortableHelperWithOverride {
  pointer-events: auto !important;
}

.ant-popover-inner {
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);
}
.ant-layout-sider {
  background: rgb(2, 0, 36);
}

.image-table-fluid {
  max-width: 120px;
  height: auto;
}

.ant-layout,
.ant-layout-footer {
  background: #e0e5ed;
  background-color: rgba(224, 229, 237, 0.5);
}

.ant-divider-horizontal.ant-divider-with-text-left {
  color: rgba(53, 79, 120, 1);
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.3);
}

.ant-modal-mask {
  background-color: rgba(13, 71, 161, 0.35);
}

/** DROPDOWN ACTIONS **/
.actions {
  max-width: 300px;
  width: 130px;
}

.actions > ul.ant-dropdown-menu {
  padding: 0;
  border-radius: 0;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.95);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.95);
}
.actions > ul.ant-dropdown-menu > li.ant-dropdown-menu-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.actions > ul.ant-dropdown-menu > li.ant-dropdown-menu-item:last-child {
  border-bottom: 0;
}

.actions > ul.ant-dropdown-menu > li.ant-dropdown-menu-item:hover > i.anticon,
.actions > ul.ant-dropdown-menu > li.ant-dropdown-menu-item:hover {
  color: #fff !important;
}

.actions > ul.ant-dropdown-menu > li.ant-dropdown-menu-item:hover {
  background-color: #0d47a1;
}

.ant-upload.ant-upload-drag {
  height: unset !important;
}

.ant-upload-list-picture > div {
  display: inline-block;
  width: 20%;
  margin-right: 5px;
}

/*****
 GENERAL FORMS
*****/
.ant-form-item {
  font-weight: 300;
}

.ant-form-item-label {
  line-height: unset;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.eye-image-fluid {
  max-width: 640px;
  max-height: 420px;
  height: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
